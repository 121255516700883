<template>
  <v-row justify="center">
    <v-dialog v-model="isSubmitOrderModalOpen" persistent max-width="360">
      <v-card>
        <v-card-title class="text-h4 font-weight-medium">
          {{ $t("supplyOrders.submit_order") }}
        </v-card-title>
        <v-card-text>
          <v-form ref="form" v-model="valid">
            <v-menu
              ref="menu"
              v-model="menu"
              :close-on-content-click="false"
              transition="scroll-y-transition"
              offset-y
              max-width="290px"
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="dateFormatted"
                  outlined
                  label="Delivery date"
                  hint="DD/MM/YYYY format"
                  persistent-hint
                  append-icon="mdi-calendar"
                  v-bind="attrs"
                  :rules="[
                    (value) => !!value || 'Required.',
                    (value) => {
                      return (
                        new Date().getTime() <
                          new Date(parseDate(value)).getTime() ||
                        'Date should be superior to the current date'
                      );
                    },
                  ]"
                  @blur="date = parseDate(dateFormatted)"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker
                class="my-0 py-2"
                v-model="date"
                no-title
                @input="menu = false"
              ></v-date-picker>
            </v-menu>
            <v-textarea
              outlined
              v-model="note"
              class="mt-4"
              name="input-7-4"
              label="Note to the supplier"
            ></v-textarea>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            class="ma-2"
            outlined
            color="indigo"
            text
            @click="toggleSubmitOrder"
          >
            {{ $t("cancel") }}
          </v-btn>
          <v-btn
            class="primary"
            text
            @click="submitOrder()"
            :loading="isLoading"
          >
            {{ $t("supplyOrders.submit") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>
<script>
import supplyOrderService from "@/store/services/supply-order-service";
export default {
  props: {
    isSubmitOrderModalOpen: Boolean,
    toggleSubmitOrder: Function,
    currentSupplyOrder: Object,
  },
  data() {
    return {
      valid: false,
      menu: null,
      date: null,
      dateFormatted: null,
      note: "",
      isLoading: false,
      id_submited: 1,
    };
  },
  watch: {
    date() {
      this.dateFormatted = this.formatDate(this.date);
    },
  },
  computed: {
    console: () => console,
  },
  methods: {
    formatDate(date) {
      if (!date) return null;

      const [year, month, day] = date.split("-");
      return `${day}/${month}/${year}`;
    },
    parseDate(date) {
      if (!date) return null;

      const [day, month, year] = date.split("/");
      return `${year}-${month.padStart(2, "0")}-${day.padStart(2, "0")}`;
    },
    submitOrder() {
      this.validate();
      if (this.valid) {
        console.log("Current Supply Order", {
          supplyOrder: this.currentSupplyOrder,
          date: this.date,
          note: this.note,
        });
        this.isLoading = true;
        this.$store
          .dispatch("supplyOrders/submitSupplyOrder", {
            supply_order_id: this.currentSupplyOrder.id,
            expected_delivery_date: this.date.replace("/", "-"),
            notes: this.note,
          })

          .then(async (data) => {
            const BDC = await supplyOrderService.getInvoice(
              this.currentSupplyOrder
            );
            const url = window.URL.createObjectURL(new Blob([BDC]));
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute(
              "download",
              `${data.supply_order.tracking_number}.pdf`
            );
            document.body.appendChild(link);
            // window.open(link);
            link.click();
            this.$store.commit("supplyOrders/SupplyOrder", {});
            this.$store.commit("products/CLEAN_LIST");
            this.$swal("", "Supply Order created successfully", "success");
            this.$router.push("/supply-orders");
            this.$store.commit("supplyOrders/SET_SELECTED", this.id_submited);
          });
      }
    },
    validate() {
      this.$refs.form.validate();
    },
  },
  mounted() {
    if (this.currentSupplyOrder.expected_delivery_date) {
      this.date = this.currentSupplyOrder.expected_delivery_date;
    }

    if (this.currentSupplyOrder.notes) {
      this.note = this.currentSupplyOrder.notes;
    }
  },
};
</script>